import { createRouter, createWebHistory } from 'vue-router'
import Construct from './routes/construct.js'
import CisAdmin from './routes/cis_admin.js'
import Demo from './routes/demo.js'
import Career from './routes/career.js'

const routes = [
  ...Construct,
  ...CisAdmin,
  ...Demo,
  ...Career,
  {
    path: "/",
    name: "HomePageView",
    component: () => import("../views/HomePageView.vue"),
  },
  {
    path: "/logout",
    name: "Logout",
    component: () => import("../views/logout.vue"),
    beforeRouteEnter(to,from,next) {
      localStorage.removeItem('token');
      localStorage.removeItem('user_id');
      localStorage.removeItem('email');
    }
  },
  {
    path: "/enter",
    name: "EnterPage",
    component: () => import("../views/EnterScreenView.vue"),
    children: [
      {
        path: "login",
        name: "Login",
        component: () => import("../components/enter-page/login/LoginComponent.vue"),
        props: true,
        /*meta: { 
          requiresAuth: true
        }*/
      }, 
      {
        path: "register",
        name: "Register",
        component: () => import("../components/enter-page/register/RegisterComponent.vue"),
        // component: () => import("../components/enter-page/employer/register/RegisterComponent.vue"),
        props: true
        /*meta: { 
          requiresAuth: true
        }*/
      },      
    ]
  },
  {
    //path: '/cabinet',
    //name: 'CabinetPageView',
    //component: () => import('../views/CabinetPageView.vue'),
    //meta: { 
    path: "/cabinet",
    name: "CabinetPageView",
    component: () => import("../views/CabinetPageView.vue"),
        /*meta: { 
          requiresAuth: true
        }*/
  },
    /*meta: { 
      requiresAuth: true
    }*/
  //},
  {
      //шаблон админки
      path: "/adminpage",
      name: "AdminPageView",
      component: () => import("../views/AdminPageView.vue"),
        /*meta: { 
          requiresAuth: true
        }*/
    children: [
      {
        //шаблон для директории из меню админки
        path: "directory",
        name: "AdminDirectoryPage",
        component: () =>
          import("../components/adminpage/AdminDirectoryPage.vue"),
        props: true,
        /*meta: { 
          requiresAuth: true
        }*/
          children: [
            //страница списка категорий справочников
            {
              path: "directories-items",
              name: "DirectoriesItems",
              component: () =>
                import(
                  "../components/adminpage/directories/DirectoriesItemsComponent.vue"
                ),
              props: true,
              /*meta: { 
                requiresAuth: true
              }*/
            },
            {
              //страница списка вариантов категории справочника
              path: "directories-list",
              name: "DirectoriesList",
              component: () =>
                import(
                  "../components/adminpage/directories/DirectoriesListComponent.vue"
                ),
              props: true,
              /*meta: { 
                requiresAuth: true
              }*/
            },
          ],
      },
    ],
  },
  {
    path: "/universal",
    name: "Universal",
    component: () =>
      import(
        "../views/UniversalView.vue"
      ),
    props: true,
    /*meta: { 
      requiresAuth: true
    }*/
    children: [
      {
        path: "resume-search",
        name: "SearchResumeView",
        component: () =>
          import(
            "../components/search-resume/SearchResumeView.vue"
          ),
        props: true,
        /*meta: { 
          requiresAuth: true
        }*/
      },
      {
        path: "my-company",
        name: "MyCompany",
        component: () =>
          import(
            "../components/my-company/MyCompanyComponent.vue"
          ),
        props: true,
        /*meta: { 
          requiresAuth: true
        }*/
        children: [
          {
            path: "info",
            name: "MyCompanyInfo",
            component: () =>
              import(
                "../components/my-company/MyCompanyInfoComponent.vue"
              ),
            props: true,
            /*meta: { 
              requiresAuth: true
            }*/
          },
          {
            path: "create",
            name: "MyCompanyCreate",
            component: () =>
              import(
                "../components/my-company/MyCompanyCreateComponent.vue"
              ),
            props: true,
            /*meta: { 
              requiresAuth: true
            }*/
          },
          {
            path: "editor",
            name: "MyCompanyEditor",
            component: () =>
              import(
                "../components/my-company/MyCompanyEditorComponent.vue"
              ),
            props: true,
            /*meta: { 
              requiresAuth: true
            }*/
          },
        ]
      },
      {
        path: "my-vacancies",
        name: "MyVacancies",
        component: () =>
          import(
            "../components/my-vacancies/MyVacanciesComponent.vue"
          ),
        props: true,
        /*meta: { 
          requiresAuth: true
        }*/
        children: [
          {
            path: "list",
            name: "MyVacanciesList",
            component: () =>
              import(
                "../components/my-vacancies/MyVacanciesList.vue"
              ),
            props: true,
            /*meta: { 
              requiresAuth: true
            }*/
          },
        ]
      },
      {
        path: "vacancy-details",
        name: "VacancyDetails",
        component: () =>
          import(
            "../components/vacancy-details/VacancyDetailsComponent.vue"
          ),
        props: true,
        /*meta: { 
          requiresAuth: true
        }*/
        children: [
          {
            path: ":name",
            name: "VacancyDetailsResponses",
            component: () =>
              import(
                "../components/vacancy-details/VacancyDetailsResponses.vue"
              ),
            props: true,
            /*meta: { 
              requiresAuth: true
            }*/
          }
        ]
      },
      {
        path: "responses-invites",
        name: "ResponsesInvites",
        component: () =>
          import(
            "../components/responses-invites/ResponsesInvitesViewComponent.vue"
          ),
        props: true,
        /*meta: { 
          requiresAuth: true
        }*/
      },
      {
        path: "resume-view",
        name: "ResumeView",
        component: () =>
          import(
            "../components/resume/ResumeViewComponent.vue"
          ),
        props: true,
        /*meta: { 
          requiresAuth: true
        }*/
      },
      {
        path: "my-resume-view",
        name: "MyResumeView",
        component: () =>
          import(
            "../components/my-resume/MyResumeViewComponent.vue"
          ),
        props: true,
        /*meta: { 
          requiresAuth: true
        }*/
      },
      {
        path: "my-resume",
        name: "MyResume",
        component: () =>
          import(
            "../components/my-resume/MyResumePage.vue"
          ),
        props: true,
        /*meta: { 
          requiresAuth: true
        }*/
      },
      {
        path: "vacancy-create",
        name: "VacancyCreate",
        component: () =>
          import(
            "../components/vacancy/VacancyCreateComponent.vue"
          ),
        props: true,
        /*meta: { 
          requiresAuth: true
        }*/
      },
      {
        path: "vacancy-preview",
        name: "VacancyPreview",
        component: () =>
          import(
            "../components/vacancy/VacancyPreviewViewComponent.vue"
          ),
        props: true,
        /*meta: { 
          requiresAuth: true
        }*/
      },
      {
        path: "vacancy-tariffs",
        name: "VacancyTariffs",
        component: () =>
          import(
            "../components/vacancy/VacancyTariffsViewComponent.vue"
          ),
        props: true,
        /*meta: { 
          requiresAuth: true
        }*/
      },
      {
        path: "vacancy-detail-view",
        name: "VacancyDetailView",
        component: () =>
          import(
            "../components/vacancy-search/VacancyDetailView.vue"
          ),
        props: true,
        /*meta: { 
          requiresAuth: true
        }*/
      },
      {
        path: "vacancy-search",
        name: "VacancySearch",
        component: () =>
          import(
            "../components/vacancy-search/VacancySearchView.vue"
          ),
        props: true,
        /*meta: { 
          requiresAuth: true
        }*/
      },
      {
        //шаблон конструктора
        path: "constructor",
        name: "ConstructorView",
        component: () => import("../components/constructor/ConstructorViewComponent.vue"),
        /*meta: { 
          requiresAuth: true
        }*/
        children: [
          {
            path: "constructor-start",
            name: "ConstructorStart",
            component: () =>
              import(
                "../components/constructor/steps/ConstructorStartComponent.vue"
              ),
            props: true,
            /*meta: { 
              requiresAuth: true
            }*/
          },
          {
            path: "constructor-data-transfer",
            name: "ConstructorDataTransfer",
            component: () =>
              import(
                "../components/constructor/steps/ConstructorDataTransferComponent.vue"
              ),
            props: true,
            /*meta: { 
              requiresAuth: true
            }*/
          },
          {
            path: "constructor-transfer-done",
            name: "ConstructorTransferDone",
            component: () =>
              import(
                "../components/constructor/steps/ConstructorTransferDone.vue"
              ),
            props: true,
            /*meta: { 
              requiresAuth: true
            }*/
          },
          {
            path: "constructor-main-info",
            name: "ConstructorMainInfo",
            component: () =>
              import(
                "../components/constructor/steps/ConstructorMainInfoComponent.vue"
              ),
            props: true,
            /*meta: { 
              requiresAuth: true
            }*/
          },
          {
            path: "constructor-competentions-profile",
            name: "ConstructorCompetentionsProfile",
            component: () =>
              import(
                "../components/constructor/steps/ConstructorCompetentionsProfileComponent.vue"
              ),
            props: true,
            /*meta: { 
              requiresAuth: true
            }*/
          },
          {
            path: "constructor-competentions-profile-info",
            name: "ConstructorCompetentionsProfileInfo",
            component: () =>
              import(
                "../components/constructor/steps/ConstructorCompetentionsProfileInfoComponent.vue"
              ),
            props: true,
            /*meta: { 
              requiresAuth: true
            }*/
          },
          {
            path: "constructor-competention-tabs",
            name: "ConstructorTabs",
            component: () =>
              import(
                "../components/constructor/steps/ConstructorTabsComponent.vue"
              ),
            props: true,
            /*meta: { 
              requiresAuth: true
            }*/
          },
          {
            path: "constructor-work-wishes",
            name: "ConstructorWorkWishes",
            component: () =>
              import(
                "../components/constructor/steps/ConstructorWorkWishesComponent.vue"
              ),
            props: true,
            /*meta: { 
              requiresAuth: true
            }*/
          },
          {
            path: "constructor-experience",
            name: "ConstructorExperience",
            component: () =>
              import(
                "../components/constructor/steps/experience/ConstructorExperienceComponent.vue"
              ),
            props: true,
            /*meta: { 
              requiresAuth: true
            }*/
          },
          {
            path: "constructor-education",
            name: "ConstructorEducation",
            component: () =>
              import(
                "../components/constructor/steps/education/ConstructorEducationComponent.vue"
              ),
            props: true,
            /*meta: { 
              requiresAuth: true
            }*/
          },
          {
            path: "constructor-complete",
            name: "ConstructorComplete",
            component: () =>
              import(
                "../components/constructor/steps/ConstructorCompleteComponent.vue"
              ),
            props: true,
            /*meta: { 
              requiresAuth: true
            }*/
          },
        ],
      },
    ]
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
