import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import VueLoading from "vue-loading-overlay";
import store from "@/store";
import "vue-loading-overlay/dist/vue-loading.css";
import VueMobileDetection from "vue-mobile-detection";
import Quill from "vue3-quill-editor";
import "vue3-quill-editor/lib/style.css";
import { tooltip } from "@/directives/tooltip.js"

const app = createApp(App);
app.directive("tooltip", tooltip);

app
  .use(router)
  .use(store)
  .use(VueAxios, axios)
  .use(VueLoading)
  .use(VueMobileDetection)
  .use(Quill)
  .mount("#app");
