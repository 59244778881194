import axios from "axios";
export default {
  namespaced: true,
  state() {
    return {
      edu_levels: [],
      ext_edu_levels: [],
    };
  },
  mutations: {
    SET_EDU_LEVELS(state, payload) {
      state.edu_levels = [...payload];
    },
    SET_EXT_EDU_LEVELS(state, payload) {
      state.ext_edu_levels = [...payload];
    },
  },
  actions: {
    getEduLevels({ commit }) {
      return axios({
        method: "GET",
        url:
          process.env.VUE_APP_API_URL +
          "/api/refbooks/refbook/get/Refbook_level_of_education",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((response) => {
          commit("SET_EDU_LEVELS", response.data.data.Refbook_level_of_education);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getExtEduLevels({ commit }) {
        return axios({
          method: "GET",
          url:
            process.env.VUE_APP_API_URL +
            "/api/refbooks/refbook/get/Refbook_ext_education",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
          .then((response) => {
            commit("SET_EXT_EDU_LEVELS", response.data.data.Refbook_ext_education);
          })
          .catch((error) => {
            console.log(error);
          });
      },
  },
  getters: {
    eduLevels: (state) => state.edu_levels,
    extEduLevels: (state) => state.ext_edu_levels,
  },
};
