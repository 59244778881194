import axios from "axios";
export default {
  namespaced: true,
  state() {
    return {
      selected_items: [],
      current_step: "",
    };
  },
  mutations: {
    SET_SELECTED_ITEMS(state, payload) {
      state.selected_items = [...payload];
    },
    SET_CURRENT_STEP(state, payload) {
      state.current_step = payload;
    },
  },
  actions: {
    setSelectedItems({ commit }, payload) {
      return commit("SET_SELECTED_ITEMS", payload);
    },
    getCurrentStep({ commit }) {
      return axios({
        method: 'GET',
        url: process.env.VUE_APP_API_URL + "/api/account/resume_builder/state",
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      })
      .then((response) => {
        commit("SET_CURRENT_STEP", response.data.data);
        })
        .catch((error) => {
          console.log(error);
        })
    },
    setCurrentStep({commit}, stepNumber) {
      return commit("SET_CURRENT_STEP", stepNumber);
    }
  },
  getters: {
    selectedItems: (state) => state.selected_items,
    currentStep: (state) => state.current_step,
  },
};
