<template>
  <router-view/>
</template>


<script>

export default {
    name: 'App'
}

</script>

<style src="./assets/style/main.css">
@import '@/assets/style/main.scss';
</style>


