import { createStore } from "vuex";
import directoriesModule from "./modules/directories.js";
import constructorModule from "./modules/constructor.js";
import educationModule from "./modules/education.js";
import locationModule from "./modules/location.js";
import createPersistedState from "vuex-persistedstate";
import axios from 'axios';
const store = createStore({
  plugins: [
    createPersistedState({ paths: ["directory_title", "breadcrumbs", "loggedUserData", "userRole"] }),
  ],
  modules: {
    directoriesModule,
    constructorModule,
    educationModule,
    locationModule
  },
  state() {
    return {
      breadcrumbs: [],
      directory_title: "",
      loggedUserData: {},
      userRole: "",
    };
  },
  mutations: {
    SET_BREADCRUMBS(state, payload) {
      state.breadcrumbs = [...payload];
    },
    SET_TITLE(state, payload) {
      state.directory_title = payload;
    },
    SET_LOGGED_USER_DATA(state, payload) {
      state.loggedUserData = payload;
    },
    SET_USER_ROLE(state, payload) {
      state.userRole = payload;
    }
  },
  actions: {
    setBreadcrumbs({ commit }, payload) {
      return commit("SET_BREADCRUMBS", payload);
    },
    setDirectoryTitle({ commit }, payload) {
      return commit("SET_TITLE", payload);
    },
    setUserData({ commit }, payload) {
      commit('SET_LOGGED_USER_DATA', payload)
    },
    setUserRole({ commit }, payload) {
      commit('SET_USER_ROLE', payload)
    },
    async getAccountData({commit}) {
      try {
        const userData = await axios.get(
        `${process.env.VUE_APP_API_URL}/api/account/resume_builder/myaccount`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        commit('SET_LOGGED_USER_DATA', userData.data.data)
      } catch(e) {
        console.log(e);
      }
    }
  },
  getters: {
    breadcrumbs: (state) => state.breadcrumbs,
    directory_title: (state) => state.directory_title,
    logged_user:(state) => state.loggedUserData,
    user_role:(state) => state.userRole
  },
});

export default store;
