import "../assets/style/tooltip.scss";
import { createTooltip } from "@/plugins/tooltip";

export const tooltip = {
  mounted(el, binding) {
    const elPositionProp = getComputedStyle(el).position;
    const elements = createTooltip(binding.value.text);
    const $tooltip = elements.$tooltip;
    const $tooltipArrow = elements.$tooltipArrow;

    if (elPositionProp == "static" || !elPositionProp) {
      el.style.position = "relative";
    }

    //если передана позиция, то вешаем обработчики
    if (binding.value.position) {
      el.addEventListener("mouseenter", onMouseEnter);
      el.addEventListener("mouseout", onMouseOut);
    }

    function onMouseEnter() {
      window.tooltipTimeout = setTimeout(() => {
        el.append($tooltip);
        // снизу слева
        if (binding.value.position == "bottom-left") {
          $tooltip.style.top = "calc(100% + 7px)";
          $tooltip.style.left = "0%";
          $tooltipArrow.style.top = "-7px";
          $tooltipArrow.style.left = "13px";
          // снизу по центру
        } else if (binding.value.position == "bottom-center") {
          $tooltip.style.top = "calc(100% + 7px)";
          $tooltip.style.left = "50%";
          $tooltip.style.transform = "translateX(-50%)";
          $tooltipArrow.style.top = "-7px";
          $tooltipArrow.style.left = "50%";
          $tooltipArrow.style.transform = "translateX(-50%)";
          // снизу справа
        } else if (binding.value.position == "bottom-right") {
          $tooltip.style.top = "calc(100% + 7px)";
          $tooltip.style.right = "0%";
          $tooltipArrow.style.top = "-7px";
          $tooltipArrow.style.right = "13px";
          // сверху слева
        } else if (binding.value.position == "top-left") {
          $tooltip.style.bottom = "calc(100% + 7px)";
          $tooltip.style.left = "0%";
          $tooltipArrow.style.bottom = "-7px";
          $tooltipArrow.style.left = "13px";
          $tooltipArrow.style.transform = "rotate(180deg)";
          // сверху по центру
        } else if (binding.value.position == "top-center") {
          $tooltip.style.bottom = "calc(100% + 7px)";
          $tooltip.style.left = "50%";
          $tooltip.style.transform = "translateX(-50%)";
          $tooltipArrow.style.bottom = "-7px";
          $tooltipArrow.style.left = "50%";
          $tooltipArrow.style.transform = "translateX(-50%) rotate(180deg)";
          // сверху справа
        } else if (binding.value.position == "top-right") {
          $tooltip.style.bottom = "calc(100% + 7px)";
          $tooltip.style.right = "0%";
          $tooltipArrow.style.bottom = "-7px";
          $tooltipArrow.style.right = "13px";
          $tooltipArrow.style.transform = "rotate(180deg)";
          // слева
        } else if (binding.value.position == "left") {
          $tooltip.style.top = "50%";
          $tooltip.style.left = "calc(0% - 7px)";
          $tooltip.style.transform = "translateX(-100%) translateY(-50%)";
          $tooltipArrow.style.top = "calc(50% - 3.5px)";
          $tooltipArrow.style.right = "-11px";
          $tooltipArrow.style.transform = "rotate(90deg)";
          // справа
        } else if (binding.value.position == "right") {
          $tooltip.style.top = "50%";
          $tooltip.style.right = "calc(0% - 7px)";
          $tooltip.style.transform = "translateX(100%) translateY(-50%)";
          $tooltipArrow.style.top = "calc(50% - 3.5px)";
          $tooltipArrow.style.left = "-11px";
          $tooltipArrow.style.transform = "rotate(-90deg)";
        }
      }, 1000);
    }
    function onMouseOut() {
      $tooltip.remove();
      clearTimeout(window.tooltipTimeout);
    }
  },
};
