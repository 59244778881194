export default [
    {
        path: '/fonts',
        name: 'FontsForDemo',
        component: () => import('../../views/cis_admin/fonts/fonts.vue'),
        meta: { 
          requiresAuth: true
        }
    },
    {
        path: '/cis_admin',
        name: 'CisAdminPage',
        component: () => import('../../views/cis_admin/CisAdminPage.vue'),
        meta: { 
          requiresAuth: true
        }
    },
    {
        path: '/cis_admin/competition/:competition_id',
        name: 'CisAdminCompetitionPage',
        component: () => import('../../views/cis_admin/CisAdminCompetitionPage.vue'),
        props: true,
        meta: { 
          requiresAuth: true
        },
        children: [
            {
                path: '/cis_admin/competition/:competition_id/wsss',
                name: 'CisAdminCompetitionWsssPage',
                component: () => import('../../views/cis_admin/CisAdminCompetitionWsssPage.vue'),
                props: true,
                meta: { 
                  requiresAuth: true
                }
            },
            {
                path: '/cis_admin/competition/:competition_id/criteria',
                name: 'CisAdminCompetitionCriteriaPage',
                component: () => import('../../views/cis_admin/CisAdminCompetitionCriteriaPage.vue'),
                props: true,
                meta: { 
                  requiresAuth: true
                }
            },
            {
              path: '/cis_admin/competition/:competition_id/sub_criteria/:criteria_id',
              name: 'CisAdminCompetitionSubCriteriaPage',
              component: () => import('../../views/cis_admin/CisAdminCompetitionSubCriteriaPage.vue'),
              props: true,
              meta: { 
                requiresAuth: true
              }
            },
            {
              path: '/cis_admin/competition/:competition_id/aspect/:sub_criteria_id',
              name: 'CisAdminCompetitionAspectPage',
              component: () => import('../../views/cis_admin/CisAdminCompetitionAspectPage.vue'),
              props: true,
              meta: { 
                requiresAuth: true
              }
            },
            {
              path: '/cis_admin/competition/:competition_id/tags',
              name: 'CisAdminCompetitionTagPage',
              component: () => import('../../views/cis_admin/CisAdminCompetitionTagPage.vue'),
              props: true,
              meta: { 
                requiresAuth: true
              }
            },
            {
                path: '/cis_admin/competition/:competition_id/search',
                name: 'CisAdminCompetitionSearchPage',
                component: () => import('../../views/cis_admin/CisAdminCompetitionSearchPage.vue'),
                props: true,
                meta: { 
                  requiresAuth: true
                }
            },
            {
                path: '/cis_admin/competition/:competition_id/search/:champ_id',
                name: 'CisAdminCompetitionSearchChampPage',
                component: () => import('../../views/cis_admin/CisAdminCompetitionSearchChampPage.vue'),
                props: true,
                meta: { 
                  requiresAuth: true
                }
            },
        ]
    },
    
]