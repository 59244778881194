export default [
    //ConstructBasePage.vue
    {
        path: '/construct',
        name: 'ConstructBasePage',
        component: () => import('../../views/construct/ConstructBasePage.vue'),
        meta: { 
          requiresAuth: true
        },
        children: [
                {
                    path: 'welcome',
                    name: 'WelcomeConstructPage',
                    component: () => import('../../views/construct/WelcomeConstructPage.vue'),
                    meta: { 
                    requiresAuth: true
                    }
                },
        ]
    },
]