export default [
    {
        path: "/career",
        name: "CareerPage",
        component: () => import("../../views/CareerView.vue"),
        children: [
          {
            path: "question-form",
            name: "QuestionForm",
            component: () => import("../../components/career/QuestionFormComponent.vue"),
            props: true,
            /*meta: { 
              requiresAuth: true
            }*/
          },      
          {
            path: "dashboard",
            name: "Dashboard",
            component: () => import("../../components/career/dashboard/DashboardComponent.vue"),
            props: true,
            /*meta: { 
              requiresAuth: true
            }*/
          },      
        ]
    },
    {
      path: "/career-enter",
      name: "EnterCareerPage",
      component: () => import("../../views/EnterCareerView.vue"),
      children: [
        {
          path: "login",
          name: "CareerLogin",
          component: () => import("../../components/enter-page/login/LoginComponent.vue"),
          props: true,
          /*meta: { 
            requiresAuth: true
          }*/
        }, 
        {
          path: "register",
          name: "CareerRegister",
          component: () => import("../../components/enter-page/register/RegisterComponent.vue"),
          // component: () => import("../components/enter-page/employer/register/RegisterComponent.vue"),
          props: true
          /*meta: { 
            requiresAuth: true
          }*/
        },      
      ]
  },
]