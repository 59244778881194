import axios from 'axios'
export default {
  namespaced: true,
  state() {
    return {
      directories_list: {},
    };
  },
  mutations: {
    SET_DIRECTORIES_LIST(state, payload) {
      state.directories_list = {...payload} 
    }
  },
  actions: {
    getDirectoriesList({commit}) {
      const directoriesNames = [
        // "Refbook_citizenship",
        "Refbook_city",
        "Refbook_company",
        "Refbook_ext_education",
        "Refbook_family_status",
        "Refbook_federal_area",
        "Refbook_field_of_activity",
        "Refbook_job_preferense",
        "Refbook_job_search_status",
        "Refbook_language",
        "Refbook_level_of_education",
        "Refbook_position",
        "Refbook_region",
        "Refbook_schedule",
        "Refbook_type_of_employment",
      ];

      let url = `${process.env.VUE_APP_API_URL}/api/refbooks/refbook/get/`;

      directoriesNames.forEach((name, index) => {
        url += index == directoriesNames.length - 1 
        ? `${name}`
        : `${name}&`
      })

      return axios(url)
      .then(response => {
        commit('SET_DIRECTORIES_LIST', response.data.data)
      });
    }
  },
  getters: {
    directories_list: (state) => state.directories_list
  },
};
