export default [
    {
        path: "/demo",
        name: "DemoPageView",
        component: () => import("@/views/demo/DemoPageView.vue"),
    },
    {
        path: "/demo-about",
        name: "AboutServiceView",
        component: () => import("@/views/demo/AboutServiceView.vue"),
    },
    {
        path: "/demo-order",
        name: "OrderPageView",
        component: () => import("@/views/demo/OrderPageView.vue"),
    },
]