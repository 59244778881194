import axios from "axios";
export default {
  namespaced: true,
  state() {
    return {
      dictionary: [], 
    };
  },
  mutations: {
    SET_DICTIONARY(state, payload) {
      state.dictionary = [...payload]
    }
  },
  actions: {
    getDictionary({ commit }) {
      return axios(
        `${process.env.VUE_APP_API_URL}/api/refbooks/refbook/regions_with_cities`
      ).then((response) => {
          commit("SET_DICTIONARY", response.data.data)
      })
    }
  },
  getters: {
    dictionary: (state) => state.dictionary,
  },
};
